import React, {useEffect} from 'react'
import { graphql } from 'gatsby'
import HeroComponent from '../components/HeroComponent/HeroComponent'
import { RichText } from '../components/Shared/RichText/RichText'
import ContactUsCards from '../components/ContactUsCards/ContactUsCards'
import Seo from '../components/seo'
import { useUrl } from '../fragments/hooks/useUrl'
import { zendeskCookieLang, zendeskChatDept } from '../utils/mapping'
import { useLocale } from '../components/hooks/useLocale'
import Breadcrum from '../components/Breadcrum/Breadcrum'
const PageEmailUs = ({
  location,
  pageContext,
  data: {
      contentfulPageCustom,
      allContentfulPageCustom: {
          nodes
      }
  }
}) => {
  const lang = useUrl()
  const locale = useLocale()
  const{heroImage, description, contactUsOptions, title, slug, seoTitle, seoDescription, snippet, breadcrum, pageTitle, cta, additionalText} = contentfulPageCustom
  const language = zendeskCookieLang[locale]
  // useEffect(() => {
  //   if (!document) {
  //     return;
  //   }
  //   const contactUs = document.createElement('script');
  //   //contactUs.src = 'https://d1a1ax4tcp3m3j.cloudfront.net/preprod-dev/TWH/nl-nl/BZA325/nl-nl_BZA325.js';
  //   contactUs.src = snippet;
  //   contactUs.async = true;
  //   document.body.appendChild(contactUs);

  //   return () => {
  //     document.body.removeChild(contactUs);
  //   }
  // })
  const zendeskFullForm = () => {
    if (!document.getElementById('zendesk-script-form')) {
        const script = document.createElement('script')
        script.setAttribute('id', "zendesk-script-form")
        // script.setAttribute('type', "text/javascript")
        script.innerHTML = `
            window.zESettings = {
                webWidget: {
                    chat: { suppress: true },
                    helpCenter: { suppress: true },
                    talk: { suppress: true },
                    answerBot: { suppress: true }
                }
            };
            window.zESettings = {
                webWidget: {
                    contactOptions:{
                    enabled: true
                    },
                    chat: {
                        enabled: ['${zendeskChatDept[locale]}'],
                        select: '${zendeskChatDept[locale]}'
                    }
                }
            };
        `

        document.body.appendChild(script)
    }

}

const zendesk = () => {
    if (!document.getElementById('ze-snippet')) {
        const script = document.createElement('script')
        script.setAttribute('id', "ze-snippet")
        script.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=6ca65b6d-456a-4555-9ac6-582ffb96a3a0`)
        script.onload = () => {
            zendeskLocale()
        }
        document.body.appendChild(script)
    }
}


const zendeskFormHide = () => {
    const script = document.createElement('script')
    script.setAttribute('id', "zendesk-script-form")
    // script.setAttribute('type', "text/javascript")
    script.innerHTML = `
            window.zESettings = {
                webWidget: {
                    chat: { suppress: true },
                    helpCenter: { suppress: true },
                    talk: { suppress: true },
                    answerBot: { suppress: true },
                }
            };
            `
    document.body.appendChild(script)
}

const zendeskLocale = () => {
    const script = document.createElement('script')
    script.setAttribute('id', "zendesk-script-locale")
    // script.setAttribute('type', "text/javascript")
    script.innerHTML = `
            zE('webWidget', 'setLocale', '${zendeskCookieLang[locale]}');
            `
    document.body.appendChild(script)
}

useEffect(() => {

    if (!document.getElementById('launcher'))
        if (locale === "en-US" ) {
          zendeskFullForm()
          zendesk()
            // zendeskLocale()
        }
        else {
            zendeskFormHide()
            zendesk()
            // zendeskLocale()
        }
    else
        zE('webWidget', 'show');

    return () => {
        zE('webWidget', 'hide');
    }


}, [])

const openPopup = () => {
  zE('webWidget', 'show');
  zE('webWidget', 'open');
}

  return (
    <section id = 'email-us'>
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        lang={lang}
        hrefLangs={nodes}
        />
      {breadcrum && <Breadcrum data ={breadcrum} title = {pageTitle}/>}
      <HeroComponent data = {heroImage} />
      <div className='container container-lg'>
        <div className='email-us-wrapper'>
          <div className='contact-us-desc'>
            <RichText content={description} />
            <button className='message-btn' onClick={() => openPopup()}>{cta.title}</button>
            <RichText content={additionalText} />
            {/* <div className='contact-form'>
              <section className='contact-us-twh-generic'>
                <form>
                
                </form>
              </section>
            </div> */}
          </div>
          <ContactUsCards gridItems = {contactUsOptions} alignment = 'row' title = {title}/>
        </div>
      </div>
    </section>
  )
}

export const PagePageContactQuery = graphql`
    query pagePageContact($id:String!, $contentful_id: String! ){
        contentfulPageCustom(id:{eq:$id}){
            seoTitle
            seoDescription{
              seoDescription
            }
            breadcrum {
              title
              slug
            }
            node_locale
            description{
              raw
            }
            additionalText{
              raw
            }
            slug
            title
            pageTitle
            heroImage {
              title
              heroImage {
                gatsbyImageData
                title
              }
              heroImageMobile {
                gatsbyImageData
                title
              }
            }
            snippet
            cta{
              title
            }
            contactUsOptions {
              title
              image {
                gatsbyImageData
                title
              }
              description {
                raw
              }
              cta{
                title
                slug
              }
            }
        }
        allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}, slug: { ne: null }}){
            nodes {
                slug
                node_locale
            }
        }
    }
`
export default PageEmailUs